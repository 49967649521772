import axios from 'axios';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Referrer = () => {
   const referrer = document.referrer;
   const pathname = useLocation().pathname;

   useEffect(() => {
      try {
         axios.post(`${process.env.REACT_APP_API_URL}/logVisitor`, { path: pathname, referer: referrer })
      } catch (error) {
         console.log(error)
      }
   }, [])
}

export default Referrer